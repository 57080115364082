require('../plugins/datepicker');

// var d = new Date(new Date().getTime());
// var t = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
// var today = d.getDate() + "/" + ('0' + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear();
// var tomorrow = t.getDate() + "/" + ('0' + (t.getMonth() + 1)).slice(-2) + "/" + t.getFullYear();
//
// //FASTBOOKER
// var arrival = $('.js-arrival');
// arrival.val(today);
// var departure = $('.js-departure');
// departure.val(tomorrow);
// arrival.datepicker({
//     format: 'dd/mm/yyyy',
//     autoclose: true,
//     startDate: 'd',
//     weekStart: 1,
//     orientation: 'bottom'
// }).on('changeDate', function (e) {
//     departure.focus();
//     var selectedDate = arrival.datepicker('getDate');
//     var nextDay = new Date(selectedDate);
//     nextDay.setDate(nextDay.getDate() + 1);
//     departure.datepicker('setDate', nextDay);
//     departure.datepicker('setStartDate', nextDay);
// });
// departure.datepicker({
//     format: 'dd/mm/yyyy',
//     autoclose: true,
//     startDate: tomorrow,
//     weekStart: 1,
//     orientation: 'bottom'
// });
var btn = $('.js-fastbooker-btn');
btn.click(function (e) {
    e.preventDefault();
    var arrival = new Date($('#startDate').val());
    var departure = new Date($('#endDate').val());
    var arrivalString = arrival.getFullYear() + "/" + ('0' + (arrival.getMonth() + 1)).slice(-2) + "/" + arrival.getDate();
    var departureString = departure.getFullYear() + "/" + ('0' + (departure.getMonth() + 1)).slice(-2) + "/" + departure.getDate();
    var href = "https://reservations.cubilis.eu/boulevard-hotel-scheveningen-ea-scheveningen?Language=" + getCubilisLang();
    if ((arrival !== 'undefined-undefined-') && (departure !== 'undefined-undefined-')) {
        href = "https://reservations.cubilis.eu/boulevard-hotel-scheveningen-ea-scheveningen/Rooms/Select?Language=" + getCubilisLang() + "&Arrival=" + arrivalString + "&Departure=" + departureString;
    }
    window.open(href, '_blank');
});


function getCubilisLang()
{
  var lang = $('html').attr('lang');
    var cubilisLang = 'en-GB';
    if (lang === 'nl') {
        cubilisLang = 'nl-NL';
    } else if (lang === 'fr') {
        cubilisLang = 'fr-FR';
    } else if (lang === 'de') {
        cubilisLang = 'de-DE';
    }
    return cubilisLang;
}
