var slick = require('slick-carousel');

$('.js-hero-slider').slick({
  autoplay: true,
  fade: true
});

$('.js-feature-slider').slick({
  autoplay: true,
  dots: true,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
      }
    }
  ]
});

$('.js-intro-gallery').slick({
  autoplay: true,
  fade: true,
  dots: true
});
