function openPopup(popup) {
  popup.classList.add('active');
}

function closePopup(popup) {
  popup.classList.remove('active');
}

(function () {
  const popup = document.querySelector('.js-popup');
  const popupBackdrop = document.querySelector('.js-popup-close');

  if (popup != null && getCookie("popupshown") == "") {
    openPopup(popup);
    if (popupBackdrop != null) {
      popupBackdrop.addEventListener('click', function(e) {
        e.preventDefault();
        closePopup(popup);
      })
    }
  }
  setCookie("popupshown", true, 1);
})();

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}