require('./modules/sliders');
//require('./modules/maps');
require('./modules/gallery');
require('./modules/forms');
require('./modules/reviews');
require('./modules/nav');
require('./modules/fastbooker');
require('./modules/popup');

require('./modules/cbpicker');

var el = document.querySelector('.js-cbpicker');

// CBPicker Config
var config = {
  el: el,
  startDateEl: document.querySelector('#startDate'),
  startDateLabel: document.querySelector('#startDateLabel'),
  endDateEl: document.querySelector('#endDate'),
  endDateLabel: document.querySelector('#endDateLabel'),
  firstDayOfWeek: 1,
  orientation: 'horizontal'
};

if(el) {
  var cbpicker = new CBPicker(config);
};
